/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby";
import GeneralFooter from '../components/generalFooter';

import Rules from "./../layouts/images/ready-images/rules-v2.pdf";

import '../layouts/css/webflow.css'
import '../layouts/css/scotties.webflow.css'
import '../layouts/css/scotties.css'
import '../layouts/css/overrides.css'
import '../layouts/css/ie.css'

if (typeof window !== 'undefined') {
  // Make scroll behavior of internal links smooth
  // eslint-disable-next-line global-require

  require('smooth-scroll')('a.anchor', {
    speed: 1000
  });
}

const Layout = ({ children, className, data }) => {
  if (typeof window !== 'undefined') {
    if (window.location.search) {
      sessionStorage.setItem('incomingsource', window.location.search);
    }
  }

  return (
  <div className={className}>
    <div>
      {children}
    </div>
    <img className="fb-pixel" height="1" width="1" src="https://www.facebook.com/tr?id=199508674022010&ev=PageView&noscript=1"/>
    <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="a4562123-e17f-4de0-8e63-b46647060a05" type="text/javascript" async></script>
    <div id="modal" />
  </div>

  )
}

Layout.propTypes = {
  children: PropTypes.func,
}

export default Layout

